import React from 'react'

const Thanku = () => {
  return (
   <>
   <div className="container" style={{marginTop:"180px"}}>
    <div className="row">
        <div className="col-md-12">
            Thank you for shopping !!!!!!!!
        </div>
    </div>
   </div>
   </>
  )
}

export default Thanku